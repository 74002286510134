import { RichText, toImageProps, VerticalCards } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React, { useMemo } from 'react';

export type TVerticalCardsConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'vertical-cards' }
>;

const VerticalCardsConnected: React.FC<
  TVerticalCardsConnectedProps
> = props => {
  const { banner, cards, isBannerLeft } = props;

  const verticalTabs = useMemo(
    () =>
      (cards ?? []).map(t => ({
        title: t.title,
        description: <RichText content={t.description} />,
      })),
    [cards],
  );

  return (
    <VerticalCards
      isBannerLeft={isBannerLeft}
      cards={verticalTabs}
      banner={toImageProps(banner, { useFill: true, desktopVW: '45vw' })}
    />
  );
};

export default VerticalCardsConnected;
